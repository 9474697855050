/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages               
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $(".swiper-pagination-switch").click(function(e){    
            e.preventDefault();
            var slideIndex = $(this).closest( "li" ).index();

            wp_swiper.slideTo(slideIndex);

            $(".swiper-pagination-switch").removeClass("active");
                    $(this).closest( "li" ).addClass("active");
        });
      
        
         var $container = $('.masonry-container');
            $container.imagesLoaded( function () {
              $container.masonry({
                columnWidth: '.item',
                itemSelector: '.item'
              });   
            });
            
        
	$('#social-stream').dcSocialStream({
		feeds: {
			twitter: {
				id: '@Open_LAquila',
                                url: mainjs.template_url + '/assets/vendor/jquery.social.stream/twitter.php'
			}, 
                        youtube: {
				id: 'UCsDaKW3ZSPtpyFy9PwkN39Q',
                                api_key: 'AIzaSyDcONmI5jRtRcjPkb-cyzAfmiGpIS2x6ho'
			},
                        instagram: {  
                                id: '!3887018604',
				accessToken: '3887018604.8a41cb9.7ae545a379d945ec86d8a81114730a04',
				clientId: '8a41cb9caa004a3f93eaec957dd72d67',
                               
			},
                    },
                    rotate: {
			delay: 0
		},
                twitterId: '@Open_LAquila',
                control: false,
		filter: true,
		wall: true,
		cache: false,
		limit: 3,
                days: 120,
		max: 'limit',
                order: 'date',
                iconPath: mainjs.template_url + '/assets/vendor/jquery.social.stream/images/dcsns-dark/',
		imagePath: mainjs.template_url + '/assets/vendor/jquery.social.stream/images/dcsns-light-1/',               
	});
       
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'agenda':{
        init: function() {
             var EventSwiper = new Swiper('.swiper-container', {        
                slidesPerView: 3,
                paginationClickable: true,
                spaceBetween: 0,
                   nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
         draggable: false,
         simulateTouch:false
            });
        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
